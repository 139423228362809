<template>
  <div class="search-condition-container">
    <div>
      <span class="title">套餐名稱</span>
      <span>{{ getSearchCondition?.name || '未設定' }}</span>
    </div>

    <div>
      <span class="title">套餐日期</span>
      <span>{{ getDateText(getSearchCondition.date) }}</span>
    </div>

    <div>
      <span class="title">狀態</span>
      <span>{{ mapAvailableStatus[getSearchCondition?.available_status]?.label || '未設定' }}</span>
    </div>

    <div class="section">
      <span class="title">套餐內容</span>
      <span>{{ getSearchCondition?.product_id ? mapProductLabel(getSearchCondition.product_id) : '未設定' }}</span>
    </div>

    <div class="section" style="grid-area: 3/1/4/3">
      <span class="title">門市</span>
      <span>
        {{
          getSearchCondition?.shop_id
            ? getSearchCondition.shop_id.split(' ')[1] + ' ' + getSearchCondition.shop_id.split(' ')[2]
            : '未設定'
        }}
      </span>
    </div>

    <div class="button-wrap">
      <el-button type="primary" style="width: 110px" @click="openDialog('searchMeal')">修改條件</el-button>
    </div>
  </div>
  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import { useGetters } from '@/utils/mapStore';
import { useDialog } from '@/utils/dialog';
import { getFormatDate } from '@/utils/datetime';
import { mapAvailableStatus, mapProductLabel } from '@/utils/define';
export default {
  name: 'search-condition',
  components: {
    SearchMealDialog: defineAsyncComponent(() => import('@/components/meal/SearchMealDialog.vue')),
  },
  setup() {
    //取得條件
    const getSearchCondition = useGetters('meal', ['getSearchCondition']);

    const getDateText = (arr) => {
      if (!arr || arr.length === 0) return '未設定';
      const d = getFormatDate();
      if (arr.every((e) => d === e)) return '當天';
      else return arr[0] + ' ~ ' + arr[1];
    };

    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return {
      mapAvailableStatus,
      mapProductLabel,
      getSearchCondition,
      dialogTemplate,
      openDialog,
      getDateText,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-condition-container {
  padding: 16px 22px;
  background-color: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-rows: repeat(3, 24px);
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  align-items: center;
  position: relative;
  span {
    font-size: 14px;
    padding-right: 16px;
    color: $color-text-light;
  }
  .section {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    padding: 1px 16px 0 1px;
    font-weight: 500;
    color: $color-text;
    width: 90px;
    display: inline-block;
  }
  .button-wrap {
    position: absolute;
    top: 16px;
    right: 22px;
  }
}
</style>
